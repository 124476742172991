.animated-svg {
    width: 100px;
    display: block;
    margin: 40px auto 0;
  }
  
  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
  }
  
  .path.circle {
    animation: dash 1.5s ease-in-out infinite;
  }
  
  .path.check {
    stroke-dashoffset: -100;
    animation: dash-check 1.5s 0.35s ease-in-out forwards infinite;
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
  