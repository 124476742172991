@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wgth@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Ubuntu", sans-serif; */
}

a {
  cursor: pointer;
}

a[class="disabled"] {
  cursor: no-drop !important;
}
.cursor{
  cursor: pointer;
}
.TEXT-BOX {
  border: 2px solid #f0f0f0 !important;
  min-height: 36px !important;
  border-radius: 5px;
  font-size: 13px;
  padding: 6px;
  gap: 6px;
  display: flex;
  flex-wrap: wrap;
}

.TEXT-BOX ul {
  padding: 0;
}

.TEXT-BOX p {
  margin: 0px !important;
}
:root {
  --blue: black;
  --white: #fff;
  --black1: #222;
  --black2: #999;
  --btnaddbackground: black;
  --btnaddcolor: #fff;
  --lightBlue: gray;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: red;
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.all_react_icons_color {
  font-size: 18px;
  cursor: pointer;
}

/* error */
.error {
  color: red;
  font-size: 12px;
}

.Star_Mark {
  color: red;
}

.INPUT-STYLE:focus {
  border: 2px solid #5c6bc0 !important;
  box-shadow: none !important;
}

.INPUT-STYLE {
  color: #ACACAC;
  background-color: #fff !important;
  border: 2px solid #f0f0f0 !important;
  font-weight: 400;
  font-size: 12px !important;
}

.PAGE-NAME {
  font-size: 25px;
  font-family: sans-serif;
  margin-bottom: 12px;
}
.BACK_ICON{
  margin-bottom: 12px;
  margin-right: 7px;
  cursor: pointer;
}

/* .mobile_number_input_box input {
  width: 100%;
  position: relative;
  padding: 6px;
  border: 2px solid #f0f0f0 !important;
  border-radius: 5px;
  font-size: 12px;
}
.mobile_number_input_box input:focus-visible {
  outline: 2px solid #5c6bc0 !important;
}

.mobile_number_input_box .PhoneInputCountrySelect[disabled] {
  display: none !important;
} */

/* Error */
/* all btn  */
.mui_button_color {
  background-color: var(--btnaddbackground) !important;
  color: var(--white) !important;
}

.save_btn {
  border: none;
  padding: 5px 20px;
  background-color: var(--btnaddbackground);
  color: var(--white);
  letter-spacing: 1.2;
  border-radius: 5px;
}

.btn-login {
  padding: 7px 15px !important;
  border-radius: 25px;
  background-color: var(--white);
  border: 2px solid var(--blue);
  font-size: 20px;
  font-weight: 500;
  transition: all 0.2s;
}


.dropdown-item {
  font-size: 12px !important;
  color: var(--lightBlue) !important;
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  text-align: left;
  padding: 0;
  display: inline;
}


.btn-login:hover {
  background-color: var(--blue);
  color: var(--white);
}

.save_btn_main {
  padding: 7px 15px !important;
  border-radius: 25px;
  background-color: var(--white) !important;
  border: 2px solid var(--blue);
  font-size: 20px;
  font-weight: 500;
  transition: all 0.2s;
}

.save_btn_main:hover {
  background-color: var(--blue);
  color: var(--white);
}

.add_btn {
  margin: 0px 0;
  margin-left: 10px;
  padding: 5px 15px;
  background-color: var(--btnaddbackground);
  color: var(--btnaddcolor);
  border: none;
}

.status_btn {
  padding: 5px 15px;
  background-color: var(--btnaddbackground);
  color: var(--btnaddcolor);
  border: none;
}

.status_btn #dropdownMenuLink {
  color: #fff;
  text-decoration: none;
}

.NoRecordFound {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.NoRecordFound .NoRecordFound_image {
  width: 400px;
  height: 300px;
}

/* end all btn  */
/* input alll */
.custom_input_box {
  background-color: #fff !important;
  border: 2px solid #f0f0f0 !important;
  min-height: 31px;
  border-radius: 5px
}

.custom_input_box p {
  margin: 0 !important;
  font-size: 13px;
}

.input_fild_main {
  border: 2px solid var(--blue);
  border-radius: 5px;
}

.input_fild_main input {
  border: none;
}

.input_fild_main input:focus {
  border: none;
}

.form-control:focus {
  box-shadow: none !important;
}

.form-select:focus {
  box-shadow: none !important;

}

/* input end all */

/* upload image */
.bodrerer {
  text-align: center;
  padding: 5%;
  border-style: dashed;
  border-radius: 15px !important;
  border: 2px solid #aaaaaa;
  position: relative;
  height: 150px;
}

.bodrerer img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: fill;
  border-radius: 15px !important;
}

.bodrerer input {
  display: none;
}

label {
  cursor: pointer;
}

.bodrerer .delete_icon_for_image {
  position: absolute !important;
  bottom: 10px !important;
  right: 50% !important;
  transform: translateX(50%);
  font-size: 25px;
  font-weight: 700;
  cursor: pointer;
  color: gray;
  transition: all 0.1s;
  opacity: 0;
}

.bodrerer:hover .delete_icon_for_image {
  bottom: -35px !important;
  opacity: 1;
}


#imageName {
  color: rgb(0, 0, 0);
  font-size: 14px;
}

.containers {
  /* position: relative; */
  width: 100%;
}

.navigation {
  /* width: 300px; */
  height: 100vh;
  background: var(--blue) !important;
  border-left: 10px solid var(--blue);
  border-right: 10px solid var(--blue);
  transition: 0.5s;
  overflow-y: auto;
}

.navigation::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

.navigation.active {
  width: 80px;
}

.navigation>ul {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
}

.navigation>ul>li .menu-box {
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.navigation>ul>li ul {
  padding: 10px;
}

.navigation>ul>li .menu-item {
  border-radius: 30px;
  background-color: var(--lightBlue);
}

.navigation>ul>li .menu-item.hide {
  height: 0px;
  overflow-y: hidden;
}

.navigation>ul>li,
.navigation>ul>li .menu-item ul li {
  position: relative;
  width: 100%;
  list-style: none;
  border-radius: 30px;
  margin-top: 8px;
  height: 45px;
  display: flex;
  align-items: center;
}

.navigation>ul>li:hover,
.navigation>ul>li.hovered {
  background: var(--white);
}

.navigation>ul>li .menu-item ul li:hover,
.navigation>ul>li .menu-item ul li.hovered {
  background: var(--white);
}


.navigation>ul>li:nth-child(1) {
  margin-bottom: 40px;
  pointer-events: none;
}

.navigation>ul>li a,
.navigation>ul>li .menu-item ul li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: var(--white);
}

.navigation>ul>li .menu-box .arrow {
  color: var(--white);
  font-size: 22px;
  cursor: pointer;
}

.navigation>ul>li:hover a,
.navigation>ul li.hovered a {
  color: var(--blue);
}

.navigation>ul>li .menu-item ul li.hovered a,
.navigation>ul>li .menu-item ul li:hover a {
  color: var(--lightBlue);

}

.navigation>ul>li:hover .menu-box .arrow,
.navigation>ul>li.hovered .menu-box .arrow {
  color: var(--blue);
}

.navigation>ul>li a .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 70px;
  text-align: center;
}

.navigation>ul>li a .icon ion-icon {
  font-size: 1.75em;
}

.navigation>ul>li a .icon .icons {
  font-size: 1.75em;
  margin-top: -12px;
}


.navigation>ul>li a .title {
  position: relative;
  display: block;
  padding: 0 10px;
  height: 60px;
  line-height: 60px;
  text-align: start;
  white-space: nowrap;
}

/* curve Outside */
.navigation>ul>li:hover a::before,
.navigation>ul>li.hovered a::before {
  content: '';
  position: absolute;
  right: 0;
  top: -50px;
  width: 50px;
  height: 50px;
  background: transparent;
  border-radius: 50%;
  /* box-shadow: 35px 35px 0 10px var(--white); */
  pointer-events: none;
}


.navigation>ul>li:hover a::after,
.navigation>ul>li.hovered a::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: -50px;
  width: 50px;
  height: 50px;
  background: transparent;
  border-radius: 50%;
  /* box-shadow: 35px -35px 0 10px var(--white); */
  pointer-events: none;
}

/* main */

.main {
  /* position: absolute; */
  /* width: calc(100% - 300px); */
  /* left: 300px; */
  height: 100vh;
  background: var(--white);
  transition: 0.5s;

}

.main.active {
  /* width: calc(100% - 80px);
  left: 80px; */

}

.topbar {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content:flex-end;
  align-items: center;
  padding: 0 10px;
}

.topbar h5 {
  text-transform: capitalize;
}

.toggle {
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5em;
  cursor: pointer;
}



.user {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  cursor: pointer !important;
  border: 2px solid black !important;
  border-radius: 50% !important;

}

.user img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.CONTENT-BOX {
  height: calc(100vh - 60px) !important;
  overflow-y: scroll !important;
  position: relative;
}

.CONTENT-BOX::-webkit-scrollbar {
  display: none;
}

/* .HEADER {
  height: 5%;
}

.CONTAINER {
  height: calc(100% - 5%);

} */

/* !!!!!!!!!!!!!!!!!!!!! login !!!!!!!!!!!!!!!!!!!!! */
.login_section {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.login {
  min-height: 400px !important;
}

.logo h1 {
  color: var(--blue);
}

.login p {
  color: #999;
}

.forgot_pin p:hover {
  color: var(--blue);
}

.input_fild {
  border: 2px solid var(--blue);
  border-radius: 5px;
}

.input_fild .form-control:focus {
  box-shadow: none;
}

.input_fild input {
  border: none;
}

.input_fild input:focus {
  border: none;
}


.anuaragagag {
  background-color: var(--blue);
}

/* !!!!!!!!!!!!!!!!!!!!! end login !!!!!!!!!!!!!!!!!!!!! */

/* !!!!!!!!!!!!! table  !!!!!!!!!!!!!!! */
.table tbody>tr:hover {
  box-shadow: 2px 2px 5px rgb(224, 223, 223), -2px -2px 5px rgb(238, 236, 236);
}


.css-177gid-MuiTableCell-root {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.css-1ex1afd-MuiTableCell-root {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.css-11w94w9-MuiTableCell-root {
  padding-top: 10px !important;
  padding-bottom: 10px !important;

}

.css-1ygcj2i-MuiTableCell-root {
  white-space: nowrap !important;
}


/* !!!!!!!!!!!!! End table  !!!!!!!!!!!!!!! */


/* !!!!!!!!!!!!!!!! list style tab !!!!!!!!! */
.list-style {
  padding: 0;
}

.list-style li {
  list-style: none;
  border: 1.5px solid var(--blue);
  padding: 12px 7px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.list-style li:hover.tab_hover_Effect {
  box-shadow: 6px 6px 12px #c5c5c5,
    -6px -6px 12px #ffffff;
}


.bgImg {
  background-image: url('./Assets/expire.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh !important;
  width: 100%;
}

.Token_expired {
  position: absolute;
  top: 40%;
  left: 20%;
}

/* DASHBOARD */
.DASHBOARD h3 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
}

.DASHBOARD .OVERVIEW_BOX.PAYMENT {
  display: flex;
}

.DASHBOARD .OVERVIEW_BOX.PAYMENT .SYMBAL {
  width: 60px;
  display: flex;
  justify-content: center;
}

.DASHBOARD .OVERVIEW_BOX.PAYMENT .ICON {
  font-size: 25px;
  color: #508eb0;
}

.DASHBOARD .OVERVIEW_BOX.PAYMENT .CONTENT {
  width: calc(100% - 60px);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.DASHBOARD .OVERVIEW_BOX.PAYMENT .TITLE {
  text-align: center;
  font-weight: 400;
  color: #a9a9a9;
  margin-bottom: 0;
}

.DASHBOARD .OVERVIEW_BOX.PAYMENT .NUMBER {
  text-align: center;
  font-weight: 700;
  background: linear-gradient(to right, #4B71B9, #508eb0, #000);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 10px;
}

.DASHBOARD .OVERVIEW_BOX.PAYMENT .PRICE {
  text-align: center;
  font-weight: 700;
  background: linear-gradient(to right, #4B71B9, #508eb0, #000);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}


.DASHBOARD .OVERVIEW_BOX.OVERVIEW .PERCANTAGE {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DASHBOARD .OVERVIEW_BOX.OVERVIEW .PERCANTAGE P {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.DASHBOARD .OVERVIEW_BOX.OVERVIEW .PERCANTAGE.GREEN P {
  color: green;
}

.DASHBOARD .OVERVIEW_BOX.OVERVIEW .PERCANTAGE.RED P {
  color: #b91414;
}

.DASHBOARD .OVERVIEW_BOX.OVERVIEW .PERCANTAGE .ICON {
  font-size: 20px;
  font-weight: 600;
  margin-left: 7px;
}

.DASHBOARD .OVERVIEW_BOX.OVERVIEW .PERCANTAGE.GREEN .ICON {
  color: green;
}

.DASHBOARD .OVERVIEW_BOX.OVERVIEW .PERCANTAGE.RED .ICON {
  color: #b91414;
}

.DASHBOARD .OVERVIEW_BOX.OVERVIEW .TITLE {
  text-align: center;
  font-weight: 400;
  color: #a9a9a9;
  margin-bottom: 0;
}

.DASHBOARD .OVERVIEW_BOX.OVERVIEW .CONTENT p {
  margin: 0;
}

.DASHBOARD .OVERVIEW_BOX.OVERVIEW .CONTENT {
  position: relative;
}

.DASHBOARD .OVERVIEW_BOX.OVERVIEW .CONTENT::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2px;
  height: 100%;
  background-color: #a9a9a9;
}

.DASHBOARD .OVERVIEW_BOX.OVERVIEW .CONTENT p:nth-child(1) {
  font-weight: 700;
  background: linear-gradient(to right, #4B71B9, #508eb0, #000);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.DASHBOARD .OVERVIEW_BOX.OVERVIEW .CONTENT p:nth-child(2) {
  color: #a9a9a9;
}

/*  MUI CSS */
.ROLE_PAGE .css-1f2kvjf-MuiFormControlLabel-root .MuiFormControlLabel-label  {
  font-size: 20px !important;
  color: #000 !important;
}

.ROLE_PAGE .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 12px !important;
}
.css-sghohy-MuiButtonBase-root-MuiButton-root{
  text-wrap: nowrap !important;
}
.css-1pqm26d-MuiAvatar-img{
  /* height: 100% !important;
  width: 100% !important; */
  object-fit:scale-down !important;
  
}

@media screen and (max-width:600px) {
  .USER_PROFILE_NAME h5 {
    font-size: 15px;
  }

  .NoRecordFound .NoRecordFound_image {
    width: 200px !important;
    height: 150px !important;
    margin-top: 20vh;
  }
}

@media screen and (max-width:300px) {
  .USER_PROFILE_NAME h5 {
    font-size: 10px;
  }
}

@media screen and (max-height: 700px) {
  .NoRecordFound .NoRecordFound_image {
    margin-top: 30vh;
  }
}